import { render, staticRenderFns } from "./NotificationBackup.vue?vue&type=template&id=b7120acc&scoped=true&"
import script from "./NotificationBackup.vue?vue&type=script&lang=js&"
export * from "./NotificationBackup.vue?vue&type=script&lang=js&"
import style0 from "./NotificationBackup.vue?vue&type=style&index=0&id=b7120acc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b7120acc",
  null
  
)

export default component.exports